<template>
  <div class="accidentmap">
    <van-nav-bar
      title="事发地点"
      left-text="返回"
      right-text="确定"
      :border="false"
      @click-left="onClickLeft"
      @click-right="confirm"
    >
    </van-nav-bar>
    <div style="width:100%;height: calc(100vh - 58px);">
      <ty-map
        ref="refTyMap"
        v-model="form.geo"
        :ty-map-mode="['point']"
        :ty-map-poi-type="11"
        :editable="editMap"
        :ty-map-org-code="tyMapOrgCode"
        :style="{ width: '100%', height: '100%' }"
        @ty-map-click="mapClick"
      ></ty-map>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Accidentmap",
  data() {
    return {
      editMap: true,
      tyMapOrgCode: "",
      form: {
        geo: {
          response2d: null
        }
      }
    };
  },
  computed: {
    ...mapState({
      geo: state => state.accidentReportStore.geo,
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.tyMapOrgCode = this.userInfo?.orgCode || "";
    if (this.geo) {
      if (this.geo.response2d) {
        this.form.geo.response2d = this.geo.response2d;
      }
      if (this.geo.request2d) {
        this.form.geo.response2d = this.geo.request2d;
      }
    }
    console.log(this.form.geo);
  },
  methods: {
    mapClick(data) {
      // this.geo = data ? data.geo : null;
    },
    cancel() {},
    confirm() {
      this.$store.commit("setGeo", this.form.geo);
      history.go(-1);
    },
    onClickLeft() {
      history.go(-1);
      // this.$store.commit("setGeo", null);
    }
  }
};
</script>

<style lang="scss">
.accidentmap {
  div {
    box-sizing: border-box;
  }
  font-family: PingFangSC-Regular, PingFang SC;
  .van-nav-bar {
    background: #ffffff;
    .van-nav-bar__title {
      color: #323233;
    }
  }
  .van-nav-bar__left {
    .van-nav-bar__text {
      font-size: 16px;
      color: #323233;
    }
  }
  .van-nav-bar__right {
    .van-nav-bar__text {
      font-size: 16px;
      color: #0076ff;
    }
  }
  .el-form-item__label {
    display: none;
  }
}
</style>
